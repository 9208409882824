import React, {ReactElement} from 'react';
import { useAccount, useConnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import metamaskIcon from '../assets/metamask.png';

const MintButton = ({allowance, onMintClick, disabled, loading, success, soldOut, hash}) => {
  const { isConnected } = useAccount()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  const button_style = "bg-white w-full h-full text-black font-bold py-2 px-4 rounded-2xl text-xl lg:text-2xl items-center text-center drop-shadow-lg"
  const button_style_active = "hover:bg-blue bg-yellow"
  const button_style_finished = "hover:bg-green-600 bg-green-500"
  const button_style_disabled = "bg-stone-400"

  function goToStaking() {
    window.location.replace(process.env.STAKING_REDIRECT_URL);
  };

  function getButtonStyles() {
    if (disabled) {
      return button_style + " " + button_style_disabled
    } else {
      return button_style + " " + button_style_active
    }
  }

  function getButtonText() : ReactElement {
    if (allowance > 0) {
      if (loading) {
        return <span>Minting..</span>
      }
      return <span>Mint now</span>
    } else {
      return <span>Not allowed to mint</span>
    }
  }

  function getMintButton() : ReactElement{
    if (soldOut) {
        return <button disabled className={button_style + " " + button_style_disabled}>SOLD OUT</button>
    }
    if (isConnected) {
      if (!success) {
        return (
          <button disabled={disabled || loading} onClick={onMintClick} className={getButtonStyles()}>
            {getButtonText()}
          </button>
        )
      }
      return (
        <button className={button_style + " " + button_style_finished} onClick={goToStaking}>
          redirect to staking..
        </button>
      )
    }
    return (
      <button className={button_style} onClick={() => connect()}>
        <img src={metamaskIcon} className="hidden md:flex fill-current absolute w-8 h-8 mr-6" alt="Metamask"/>
        <span>Connect to metamask</span>
      </button>
    )
  }

  return getMintButton()
}

export default MintButton
