import React from "react";
import './App.css'
import * as Sentry from "@sentry/react";
import { publicProvider } from 'wagmi/providers/public'

import {
  WagmiConfig,
  createClient,
  configureChains,
  chain,
} from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { infuraProvider } from '@wagmi/core/providers/infura'
import { InjectedConnector } from 'wagmi/connectors/injected'
import MintPage from "./components/MintPage";
import {BrowserTracing} from "@sentry/tracing";


Sentry.init({
  dsn: "https://a4e7606f3ff640acb5c25e8ee6ed2a9e@o4504106369351680.ingest.sentry.io/4504202769203200",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
});

function App() {

  const getChain = (network: string) => {
    if (network === 'mainnet') {
      return chain.mainnet
    } else if (network === 'goerli') {
      return chain.goerli
    }
    return chain.goerli
  }

 const { chains, provider } = configureChains([getChain(process.env.NETWORK)], [
    infuraProvider({ apiKey: process.env.INFURA_API_KEY }),
    alchemyProvider({ apiKey: process.env.ALCHEMY_API_KEY }),
    publicProvider(),
  ])

  const wagmiClient = createClient({
    autoConnect: true,
    connectors: [new InjectedConnector({ chains })],
    provider,
  })

  return (
      <WagmiConfig client={wagmiClient}>
        <MintPage />
      </WagmiConfig>
  );
}

export default Sentry.withProfiler(App);
