import React from 'react'
import {isUserWhitelisted} from "../utils/whitelists";
import {useAccount, useConnect} from "wagmi";
import {InjectedConnector} from "wagmi/dist/connectors/injected";

const WhitelistIndicator = () => {
  const {address} = useAccount()
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  })

  function getIsUserWhitelisted() {
    if (isUserWhitelisted(1, address)) {
      return <span className="text-green-600">Allowlisted</span>
    } else if (isUserWhitelisted(2, address)) {
      return (<span className="text-green-600">Waitlisted</span>)
    }
    return (<span className="text-red-600">Not on allowlist</span>)
  }

  function getWalletSubString() {
    if (address) {
      return address.substring(0, 4) + "..." + address.substring(address.length - 6, address.length)
    }
  }

  return (
    <div className="bg-[#FFFFFF] w-[200px] rounded-xl p-2 p-4 drop-shadow-lg">
    {
      address ?
        <div>
          {getWalletSubString()} <span className="float-right">{getIsUserWhitelisted()}</span>
        </div>
        :
        <div className="text-center">
          <button onClick={() => connect()}>
            Not connected
          </button>
        </div>
    }
    </div>
  )
}

export default WhitelistIndicator