import React, { useEffect } from 'react'
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'
import * as Sentry from "@sentry/react";
import MintButton from "./MintButton";
import abi from '../contracts/founders.json';
import soulboundAbi from '../contracts/SoulboundFoundersKey.json';
import { getProof, isUserWhitelisted, whitelists } from "../utils/whitelists";
import { weiToEth } from "../utils/web3";
import { BigNumber } from "ethers";
import { allowlistPhase, inactivePhase, publicPhase, waitlistPhase } from "./MintPage";

const MintCard = ({ mintPhase }) => {
  const maxOmnipotentSupply = Number(process.env.MAX_OMNIPOTENT_SUPPLY)
  const maxOmnipotentMintsPerWallet = Number(process.env.MAX_OMNIPOTENT_MINTS_PER_WALLET)
  const mintPrice = BigNumber.from(process.env.MINT_PRICE)

  const [allowance, setAllowance] = React.useState(null);
  const [totalNumberMinted, setTotalNumberMinted] = React.useState(null);
  const [numberMinted, setNumberMinted] = React.useState(0);
  const [numberStaked, setNumberStaked] = React.useState(0);
  const [numberToMint, setNumberToMint] = React.useState(0);
  const [totalPrice, setTotalPrice] = React.useState(numberToMint * mintPrice);
  const [soldOut, setSoldOut] = React.useState(false);
  const [proof, setProof] = React.useState();
  const [prepareError, setPrepareError] = React.useState(null)
  const [publicTransactionIsSuccess, setPublicTransactionIsSuccess] = React.useState(false)
  const [publicTransactionIsError, setPublicTransactionIsError] = React.useState(false)
  const [allowlistTransactionIsError, setAllowlistTransactionIsError] = React.useState(false)
  const [allowlistTransactionIsSuccess, setAllowlistTransactionIsSuccess] = React.useState(false)
  const [allDataLoaded, setAllDataLoaded] = React.useState(false)
  const { chain } = useNetwork()
  const { address } = useAccount()
  const treasuryMint = 50

  const totalNumbermintedByAddress = () => {
    return numberMinted + numberStaked;
  }

  useEffect(() => {
    getMaxAllowance();
  });

  useEffect(() => {
    if (address) {
      Sentry.setUser({ address: address });
    }
  }, [address])

  useEffect(() => {
    if (chain) {
      Sentry.setContext("Chain", {
        "name": chain.name,
        "id": chain.id,
      })
    }
  }, [chain])

  useEffect(() => {
    if (
      totalNumberMinted &&
      maxOmnipotentSupply &&
      maxOmnipotentMintsPerWallet &&
      mintPrice
    ) {
      setAllDataLoaded(true)
    }
  }, [allowance, totalNumberMinted, numberMinted, maxOmnipotentSupply, maxOmnipotentMintsPerWallet, mintPrice])

  useEffect(() => {
    if (numberToMint > 0) {
      setTotalPrice(numberToMint * mintPrice);
    } else {
      setTotalPrice(0);
    }
  }, [numberToMint])

  useEffect(() => {
    if (allowance - totalNumbermintedByAddress() < 1) {
      setNumberToMint(0)
      return
    }
    if (allowance - numberToMint > 0 && totalNumbermintedByAddress() < maxOmnipotentMintsPerWallet) {
      setNumberToMint(1)
      return
    }
    setNumberToMint(0);
  }, [allowance, numberMinted, numberStaked])


  useEffect(() => {
    setSoldOut(totalNumberMinted >= maxOmnipotentSupply)
  }, [totalNumberMinted, maxOmnipotentSupply])

  React.useEffect(() => {
    setTotalPrice(numberToMint * mintPrice);
  }, [numberToMint])

  function canIncrease() {
    return !(numberToMint >= 1 || (numberToMint >= (allowance - totalNumbermintedByAddress())));
  }

  function increaseNumberToMint() {
    // We only allow for 1 per transaction
    if (!canIncrease()) return
    setNumberToMint(numberToMint + 1)
  }

  function decreaseNumberToMint() {
    if (numberToMint == 0) return
    setNumberToMint(numberToMint - 1)
  }

  function getPhaseName() {
    if (mintPhase == waitlistPhase) {
      return "waitlist"
    } else if (mintPhase == allowlistPhase || mintPhase == inactivePhase) {
      return "allowlist"
    } else {
      return "public"
    }
  }

  function getMaxAllowance() {
    if (address == null) {
      return
    }
    if (mintPhase == inactivePhase) {
      setAllowance(0)
    }
    if (mintPhase == allowlistPhase) {
      if (isUserWhitelisted(1, address)) {
        setAllowance(whitelists['1']["allowance"])
      } else {
        setAllowance(0)
      }
    } else if (mintPhase == waitlistPhase) {
      // Allowlisted users that didn't mint during phase 1 are still permitted to mint.
      if (isUserWhitelisted(2, address)) {
        setAllowance(whitelists['2']["allowance"])
      } else {
        setAllowance(0)
      }
    } else if (mintPhase == 3) {
      setAllowance(maxOmnipotentMintsPerWallet)
    }
  }

  useContractRead({
    address: process.env.CONTRACT_ADDRESS,
    abi: abi.result,
    functionName: 'getNumberMinted',
    args: [address],
    onSuccess: (data) => {
      setNumberMinted(data.toNumber());
    },
    onError(error) {
      Sentry.captureException(error)
    },
    watch: false
  })

  useContractRead({
    address: process.env.SOULBOUND_ADDRESS,
    abi: soulboundAbi.abi,
    functionName: 'balanceOf',
    args: [address],
    onSuccess: (data) => {
      setNumberStaked(data.toNumber());
    },
    onError(error) {
      Sentry.captureException(error)
    },
    watch: false
  })

  useContractRead({
    address: process.env.CONTRACT_ADDRESS,
    abi: abi.result,
    functionName: 'totalSupply',
    onSuccess: (data) => {
      setTotalNumberMinted((data as BigNumber).toNumber());
    },
    onError(error) {
      Sentry.captureException(error)
    },
    watch: false
  })

  const {
    config: allowlistConfig,
    error: prepareAllowlistMintError
  } = usePrepareContractWrite({
    address: process.env.CONTRACT_ADDRESS,
    abi: abi.result,
    functionName: 'omnipotentAllowlistMint',
    overrides: {
      value: mintPrice,
    },
    args: [proof],
    enabled: Boolean(proof),
    onSettled(data, error) {
      mintOmnipotent()
    },
    onError(error) {
      Sentry.captureException(error)
    }
  })

  const { data: allowlistData, isLoading: isAllowlistLoading, isError: allowlistContractWriteIsError, write: allowlistWrite } = useContractWrite({
    ...allowlistConfig,
    onError(error) {
      Sentry.captureException(error)
    }
  })

  const { isLoading: allowlistIsLoading, isError: allowlistTransactionIsHTTPError } = useWaitForTransaction({
    hash: allowlistData?.hash,
    onError(error: Error) {
      Sentry.captureException(error)
    },
    onSuccess(data) {
      if (data.status == 1) {
        // success
        setAllowlistTransactionIsSuccess(true)
      } else {
        // error
        setAllowlistTransactionIsError(true)
        Sentry.captureMessage("Transaction failed with logs " + data.logs)
      }
    }
  })

  const {
    config: publicConfig,
    error: preparePublicMintError
  } = usePrepareContractWrite({
    address: process.env.CONTRACT_ADDRESS,
    abi: abi.result,
    functionName: 'omnipotentMint',
    overrides: {
      value: mintPrice,
    },
    onError(error) {
      Sentry.captureException(error)
    },
    enabled: (mintPhase == publicPhase && numberToMint > 0),
  })

  useEffect(() => {
    if (allowance - totalNumbermintedByAddress() < 1) {
      // Transaction will fail because user is no longer allowed.
      return
    }
    if (mintPhase == waitlistPhase || mintPhase == allowlistPhase && prepareAllowlistMintError) {
      setPrepareError(prepareAllowlistMintError)
    } else if (mintPhase == publicPhase && preparePublicMintError) {
      setPrepareError(preparePublicMintError)
    }
  }, [prepareAllowlistMintError, preparePublicMintError, mintPhase])

  const { data: publicData, isLoading: isPublicLoading, isError: publicContractWriteIsError, write: publicWrite } = useContractWrite({
    ...publicConfig,
    onError(error: Error) {
      Sentry.captureException(error)
    },
  })

  const { isLoading: publicIsLoading, isError: publicTransactionIsHTTPError } = useWaitForTransaction({
    hash: publicData?.hash,
    onError(error: Error) {
      Sentry.captureException(error)
    },
    onSuccess(data) {
      if (data.status == 1) {
        // success
        setPublicTransactionIsSuccess(true)
      } else if (data.status == 0) {
        // error
        setPublicTransactionIsError(true)
        Sentry.captureMessage("Transaction failed with logs " + data.logs)
      }
    }
  })

  useEffect(() => {
    if (allowlistTransactionIsSuccess || publicTransactionIsSuccess) {
      setTotalNumberMinted(totalNumberMinted + numberToMint);
      setTimeout(function () { window.location.replace(process.env.STAKING_REDIRECT_URL); }, 5000);
    }
  }, [allowlistTransactionIsSuccess, publicTransactionIsSuccess])

  const mintOmnipotent = () => {
    if (!isAllowlistLoading) {
      allowlistWrite?.()
    }
  }

  function listMint() {
    if (numberToMint > 0) {
      if (mintPhase == 1) {
        if (isUserWhitelisted(1, address)) {
          setProof(getProof(1, address))
        }
      } else if (mintPhase == 2) {
        if (isUserWhitelisted(2, address)) {
          setProof(getProof(2, address))
        }
      }
      mintOmnipotent()
    }
  }

  function publicMint() {
    if (numberToMint > 0 && !isPublicLoading) {
      publicWrite?.()
    }
  }

  function getPrepareErrorMessage() {
    if (prepareError != null) {
      let string_message = "Something prevents you from minting."
      try {
        string_message = prepareError["error"]["message"]
      } catch {
        return string_message
      }

      if (string_message.includes("insufficient funds")) {
        return "You do not have enough funds for estimated mint + gas fee."
      }
      return string_message
    }
  }

  function isHttpError(): boolean {
    return publicTransactionIsHTTPError || allowlistTransactionIsHTTPError || publicContractWriteIsError || allowlistContractWriteIsError
  }

  function isTransactionError(): boolean {
    return publicTransactionIsError || allowlistTransactionIsError
  }

  function getBorderColor(): string {
    if (isTransactionError()) {
      return "border-red-500"
    } else if (allowlistTransactionIsSuccess || publicTransactionIsSuccess) {
      return "border-green-500"
    }

    return "border-yellow"
  }

  function getHash() {
    if (allowlistTransactionIsSuccess) {
      return allowlistData?.hash
    } else if (publicTransactionIsSuccess) {
      return publicData?.hash
    }
  }

  function getMax(): number {
    const max = allowance - totalNumbermintedByAddress()
    if (max >= 0) {
      return max
    } else {
      // can be less then 0 when allowance is 0 in different phase.
      return 0
    }
  }

  return (
    <div>
      {allDataLoaded ?
        <div
          className={"bg-grey mt-24 rounded-2xl w-full text-neutral-200 flex flex-col border-4 text-center p-6 text-white relative " + getBorderColor()}>
          <h2 className="lg:text-5xl text-3xl text-white inline pt-6 m-0 pb-4">{(publicTransactionIsSuccess || allowlistTransactionIsSuccess) ?
            <span>CONGRATULATIONS</span> : <span>OMNIPOTENT {getPhaseName()} SALE</span>}</h2>
          <div
            className="inset-0 gap-x-24 gap-y-8 border-solid border-2 border-white rounded-2xl flex flex-col md:flex-row p-8 mb-8">
            <div className="flex flex-col flex-auto ">
              <div className="top-0 text-2xl inline m-0">Remaining</div>
              <div
                className="inset-x-0 bottom-0 text-4xl">{maxOmnipotentSupply - totalNumberMinted}/{maxOmnipotentSupply - treasuryMint}</div>
            </div>
            <div className="flex flex-col flex-auto">
              <div className="top-0 text-2xl inline m-0 ">Price</div>
              <div className="inset-x-0 bottom-0 inline m-0  text-4xl">{weiToEth(mintPrice).toString()} ETH</div>
            </div>
          </div>
          {(!publicTransactionIsSuccess && !allowlistTransactionIsSuccess && !soldOut) &&
            <div>
              <div
                className="inset-0 border-solid border-2 border-white p-1 flex flex-row bg-neutral-400 text-[#464646] text-lg">
                <div className="flex pl-2 p-1 flex-row flex-auto float-right text-4xl">
                  <div className={`p-1 cursor-pointer ${numberToMint != 0 ? "" : "text-stone-500"}`}
                    onClick={decreaseNumberToMint}>-
                  </div>
                  <div className="p-1">{numberToMint}</div>
                  <div className={`p-1 cursor-pointer ${canIncrease() ? "" : "text-stone-500"}`}
                    onClick={increaseNumberToMint}>+
                  </div>
                </div>
                <div className="pr-2 pt-2 h-max align-middle">
                  <div className="text-4xl pr-2 align-middle">{getMax()}<span
                    className="text-xl pl-2">MAX</span></div>
                </div>
              </div>
              <div className="pt-2 h-max float-right text-xl">You can mint up to <span
                className="text-yellow">{getMax()}</span> more times
              </div>
              <div className="h-[2px] mt-12 mb-12 bg-white" />
              <div className="flex flex-row flex-auto text-4xl pb-16">
                <div className="md:flex-none md:w-1/2" />
                <div className="flex-auto">TOTAL:</div>
                <div className="flex-auto">{weiToEth(mintPrice) * numberToMint} ETH</div>
              </div>
            </div>
          }
          <div className="grow h-16 pb-2">
            <MintButton
              allowance={allowance - totalNumbermintedByAddress()}
              onMintClick={(mintPhase == publicPhase) ? publicMint : listMint}
              disabled={(numberToMint < 1)}
              loading={(allowlistIsLoading || publicIsLoading)}
              success={(allowlistTransactionIsSuccess || publicTransactionIsSuccess)}
              soldOut={soldOut}
              hash={getHash()}
            />
            {isHttpError() && <div className="pt-2 pb-2 text-red-500">Something went wrong</div>}
            {(prepareError && !soldOut) && <div className="pt-2 pb-2 text-red-500">{getPrepareErrorMessage()}</div>}
          </div>
        </div>
        :
        <div
          className={"bg-grey mt-32 rounded-2xl w-full text-neutral-200 flex flex-col border-4 text-center p-6 text-white relative " + getBorderColor()}>
          <h2 className="text-5xl text-white inline pt-6 m-0 pb-4">Loading collection data..</h2>
        </div>
      }
    </div>
  )
}

export default MintCard
