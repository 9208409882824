import {BigNumber} from "ethers";

export function weiToEth(wei: BigNumber): number {
  try {
    const number = wei.div(10 ** 9).toNumber();
    return number / 10 ** 9;
  } catch (TypeError) {
    return 0;
  }
}
