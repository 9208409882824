import React from "react";
import Navbar from './Navbar'
import MintCard from "./MintCard";
import WhitelistIndicator from "./WhitelistIndicator";

export const inactivePhase = 0;
export const allowlistPhase = 1;
export const waitlistPhase = 2;
export const publicPhase = 3;

const MintPage = () => {
  const omnipotentAllowlist = {
    "startTime": Number(process.env.ALLOWLIST_MINT_START_TIME),
    "endTime": Number(process.env.ALLOWLIST_MINT_END_TIME),
    "allowance": 1,
    "mintPhase": 1,
  }
  const omnipotentWaitlist = {
    "startTime": Number(process.env.WAITLIST_MINT_START_TIME),
    "endTime": Number(process.env.WAITLIST_MINT_END_TIME),
    "allowance": 2,
    "mintPhase": 1,
  }
  const publicSaleStartTime = Number(process.env.PUBLIC_MINT_START_TIME);
  const [currentPhase, setCurrentPhase] = React.useState(0);

  function getCurrentPhase() {
    if (omnipotentAllowlist == null || omnipotentWaitlist == null || publicSaleStartTime == null) return 0
    // to seconds
    const currentDate = Date.now() / 1000
    if (currentDate <= omnipotentAllowlist["endTime"] && currentDate >= omnipotentAllowlist["startTime"]) {
      return allowlistPhase
    } else if (currentDate <= omnipotentWaitlist["endTime"] && currentDate >= omnipotentWaitlist["startTime"]) {
      return waitlistPhase
    } else if (currentDate >= publicSaleStartTime) {
      return publicPhase
    } else if (omnipotentAllowlist["startTime"] > currentDate) {
      return inactivePhase
    }
  }

  function setNextPhaseIfNeeded() {
    setCurrentPhase(getCurrentPhase())
  }

  function getCountDownTimeInMillis(): number {
    if (omnipotentAllowlist == null || omnipotentWaitlist == null || publicPhase == null) return Date.now()
    const currentPhase_ = getCurrentPhase()
    if (currentPhase_ == inactivePhase) {
      return omnipotentAllowlist["startTime"] * 1000
    } else if (currentPhase_ == allowlistPhase) {
      return omnipotentAllowlist["endTime"] * 1000
    } else if (currentPhase_ == waitlistPhase) {
      return omnipotentWaitlist["endTime"] * 1000
    } else {
      return Date.now()
    }
  }

  return (
    <div>
      {(currentPhase != null) &&
        <div className="m:h-screen relative z-10 bg-red flex flex-col h-screen justify-between font-bebas">
          <div className="pr-16 pt-8">
            <div className="float-right">
              <WhitelistIndicator></WhitelistIndicator>
            </div>
          </div>
          <main className="mb-auto">
            <div className="flex flex-col items-center w-full">
              <div className="flex-2 w-64">
              </div>
              <div className="flex-1 w-9/12 max-w-[500px] pb-24">
                <MintCard mintPhase={currentPhase} />
              </div>
            </div>
          </main>
          <div className="h-10 pl-16 pr-16 pb-32">
            <Navbar phase={currentPhase} countDownTime={getCountDownTimeInMillis} countDownCallback={setNextPhaseIfNeeded} />
          </div>
        </div>
      }
    </div>
  );
}

export default MintPage;
