import React from 'react'
import Logo from '../assets/logo.png'
import NavCards from '../assets/navcards.png'
import { FaDiscord, FaTwitter } from "react-icons/fa"
import {useCountdown} from "../utils/useCountdown";
import {publicPhase, inactivePhase, allowlistPhase, waitlistPhase} from "./MintPage";

const Navbar = ({phase, countDownTime, countDownCallback}) => {
  const [days, hours, minutes, seconds] = useCountdown(countDownTime, countDownCallback);

  function getTimeFormatted(days, hours, minutes, seconds) {
    return `${days}d ${hours}h ${minutes}m ${seconds}s`
  }

  function getTimerIfRequired() {
    if (phase == inactivePhase) {
      return `ALLOWLIST SALE STARTS IN: ${getTimeFormatted(days, hours, minutes, seconds)}`
    } else if (phase == allowlistPhase) {
      return `ALLOWLIST SALE ENDS IN: ${getTimeFormatted(days, hours, minutes, seconds)}`
    } else if (phase == waitlistPhase) {
        return `WAITLIST SALE ENDS IN: ${getTimeFormatted(days, hours, minutes, seconds)}`
    } else if (phase == publicPhase) {
      return "PUBLIC SALE IS LIVE"
    }
  }

  return (
    <div>
      <nav className="bg-[#FBEB3B] text-black rounded-xl p-2 lg:p-4 drop-shadow-lg">
        <div className="flex flex-row items-center py-2 px-4 justify-between relative">

          <div className="flex flex-col lg:flex-row lg:space-x-8 items-center space-y-4 lg:space-y-0">
            <img className="hidden md:flex w-16" src={Logo} alt="Naffles" />
            <div className="hidden lg:flex space-x-10 px-8 text-2xl">
              <a href="https://twitter.com/nafflesofficial" target="_blank">
                <FaTwitter className="hover:text-[#857c1b] transition-colors ease-out duration-150 cursor-pointer" />
              </a>
              <a target="_blank" href="https://discord.gg/naffles">
                <FaDiscord className='hover:text-[#857c1b] transition-colors ease-out duration-150 cursor-pointer' />
              </a>
            </div>
          </div>
          <div className="xl:visible invisible">
            <img src={NavCards} className="absolute left-0 right-0 ml-auto mr-auto navbarImage"/>
          </div>
          <div className="flex text-2xl flex-row text-center">
            {getTimerIfRequired()}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar